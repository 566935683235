export const TRANSLATIONS_ES = {
    nav: {
        solution:'Solución',
        function:'Funciones',
        testimonials: 'Testimonios',
        prices: 'Piloto',
        blog: 'Blog',
        signIn: 'Iniciar sesión',
        selectLanguage:'Selecciona un idioma',
        back:'atrás'
    },
    whatsApp: {
        replyTime: 'Te contestaremos a la brevedad',
        message: 'En que te podemos ayudar?',
        inputPlaceHolder: 'Escribe un mensaje',
        sendButtonText: 'Mandar'
      },
    section: {
        // FUNCTIONS
       'braveup-data': {
            home: {
                title: 'BRAVE UP! DATA',
                subTitle: 'Sociograma Fortalecido',
                description: 'En el constante esfuerzo por construir entornos escolares seguros y positivos, nos complace presentarte BRAVE UP! Sociograma, la herramienta definitiva para mejorar la convivencia en tus clases y brindar el apoyo necesario a cada estudiante.',
                noImage:'Imagen Computador'
            },
            description: {
                title: '¿Qué hace el Sociograma?',
                description:"",
                items:{
                    title:'Detección avanzada',
                    description:'Nuestra plataforma utiliza tecnología basada en evidencia para identificar patrones de comportamiento potencialmente problemáticos, proporcionando alertas tempranas sobre situaciones de convivencia antes de que se agraven.',
                    title1:'Monitoreo continuo',
                    description1:'Mantén una vigilancia constante sobre las interacciones en tu curso, permitiéndote comprender mejor las dinámicas y las necesidades individuales de cada estudiante.',
                    title2:'Grupos de trabajo',
                    description2:'Proporcionamos actividades diseñadas para fomentar la participación activa y el aprendizaje práctico. Desde dinámicas de grupo hasta ejercicios individuales, nuestros recursos ofrecen herramientas versátiles para cualquier entorno educativo.',
                }
            },
            reasons: {
                title: '¿Por qué BRAVE UP?',
                subTitle:'',
                items:{
                    title: 'Instrumento de calidad',
                    description: 'Diseñamos nuestras preguntas de sociograma basándonos en las mejores prácticas internacionales y la colaboración con colegios asociados. Con más de 45 mil estudiantes que han respondido exitosamente, garantizamos un instrumento validado metodológicamente.',
                    title1: 'Compromiso con la comunidad escolar',
                    description1: 'Creemos en la importancia de escuchar a cada miembro de la comunidad escolar. Con la participación de más de 250 colegios, entendemos que son ellos quienes mejor conocen su entorno y experiencia educativa.',
                }
            },
        },
        'modo-braveup': {
            home: {
                title: 'MODO BRAVE UP!',
                subTitle: 'Un canal directo',
                subTitle1:'Entre tus estudiantes, equipos educativos y de convivencia',
                description: 'Modo BRAVE UP!, un canal de comunicación que revoluciona la forma en que abordamos los desafíos de convivencia escolar. Esta innovadora función forma parte de la plataforma BRAVE UP! y se posiciona como una herramienta esencial para fortalecer los lazos de confianza en tu comunidad educativa.',
                noImage:'Imagen Computador'
            },
            description: {
                title: '¿Qué ofrece el Modo BRAVE UP!?',
                description:"",
                items:{
                    title:'Reportes Anónimos Confiables',
                    description:'Facilitamos un espacio seguro y confidencial donde los miembros de tu comunidad escolar pueden comunicarse directamente con el equipo de convivencia. El anonimato fomenta la apertura y permite abordar situaciones sensibles de manera efectiva.',
                    title1:'Comunicación Directa entre tus estudiantes, equipos educativos y de convivencia',
                    description1:'Rompemos barreras facilitando la comunicación directa entre estudiantes, personal educativo y el equipo de convivencia. El Modo BRAVE UP! es un canal eficiente para compartir inquietudes, situaciones de acoso o cualquier problema que requiera intervención.',
                    title2:'Respuesta Rápida y Efectiva',
                    description2:'Con el Modo BRAVE UP!, las preocupaciones son atendidas de manera oportuna. Esto permite una respuesta rápida y efectiva, abordando los desafíos de convivencia antes de que se agraven.',
                }
            },
            reasons: {
                title: '¿Por qué elegir el Modo BRAVE UP!?',
                subTitle:'',
                items:{
                    title: 'Cultura de Confianza',
                    description: 'Al implementar un canal de reportes anónimos, promovemos una cultura de confianza que empodera a los(as) miembros de la comunidad escolar para expresar sus inquietudes de manera segura.',
                    title1: 'Integración con BRAVE UP!',
                    description1: 'El Modo BRAVE UP! se integra perfectamente con la visión integral de BRAVE UP!, fortaleciendo la salud mental y emocional de los(as) estudiantes y contribuyendo a un entorno educativo más seguro y positivo.',
                    title2: 'Resultados Tangibles',
                    description2: 'Avalado por la experiencia de escuelas que confían en BRAVE UP!, el Modo BRAVE UP! ha demostrado ser una herramienta efectiva para mejorar la comunicación y resolver conflictos de convivencia.'
                }
            },
        },
        'braveup-acciona': {
            home: {
                title: 'Brave up! Acciona',
                subTitle: 'Tu guía virtual',
                description: 'Nos complace presentarte BRAVE UP! ACCIONA, nuestro Sistema de Recomendación diseñado para unir el mundo de las redes y los recursos educativos, específicamente enfocado en fortalecer la convivencia escolar.',
                noImage: 'Sistema IA de Recomendación educativo'
            },
            description: {
                title: '¿Qué ofrece Acciona?',
                description: '',
                items:{
                    title: 'Conexiones relevantes',
                    description: 'Acciona utiliza la información proporcionada por expertos para crear conexiones significativas entre las necesidades específicas de la convivencia escolar y los recursos educativos disponibles en nuestra plataforma.',
                    title1: 'Asesoramiento Especializado',
                    description1: 'Gracias a la intervención directa de expertos, Acciona brinda asesoramiento especializado sobre qué recursos son más apropiados para abordar situaciones particulares, creando un enfoque personalizado y efectivo.',
                    title2: 'Eficiencia en la toma de decisiones',
                    description2: 'Al tener acceso a las recomendaciones de expertos a través de Acciona, los(as) educadores y miembros de la comunidad escolar pueden tomar decisiones informadas y eficientes para mejorar la convivencia en su entorno educativo.'
                }
            },
            reasons: { },
        },
        'braveup-dce': {
            home: {
                title: 'Brave up! DCE',
                subTitle: 'Diagnóstico de convivencia escolar',
                description: 'En el constante esfuerzo por construir entornos escolares seguros y positivos, nos complace presentarte el Diagnóstico de Convivencia Escolar, la herramienta definitiva para mejorar la convivencia en tus cursos y brindar el apoyo necesario a cada estudiante.',
                noImage: 'Diagnostico de convivencia escolar'
            },
            description: {
                title: '¿Qué hace el Diagnóstico de Convivencia Escolar??',
                description: '',
                items:{
                    title: 'Evaluación integral',
                    description: 'Su objetivo es facilitar la generación de planes de intervención focalizados en aquellas áreas que presenten mayores oportunidades de mejora, optimizando y reorientando la asignación de recursos y esfuerzos por parte de los equipos de convivencia.',
                    title1: 'Reportabilidad',
                    description1: 'Permiten comprender las percepciones de docentes, asistentes de la educación, estudiantes y familias en torno a la convivencia en la escuela. Esto refuerza nuestro compromiso con relevar la convivencia como un aspecto clave y constitutivo del proceso de enseñanza y aprendizaje.',
                }
            },
            reasons: {
                title: '¿Por qué elegir BRAVE UP! Diagnóstico de Convivencia Escolar',
                subTitle: '',
                items: {
                    title: 'Enfoque en el bienestar socioemocional',
                    description: 'Nuestra herramienta prioriza la comprensión profunda del bienestar socioemocional, abordando aspectos cruciales para el desarrollo integral de los(as) estudiantes, docentes y personal administrativo. Al elegir Brave Up, te comprometes a evaluar y mejorar la salud mental y emocional en tu comunidad educativa.',
                    title1: 'Gestión de la Convivencia como Elemento Trascendental',
                    description1: 'Brave Up destaca la gestión de la convivencia como un elemento trascendental en la toma de decisiones. Nuestro instrumento te brinda la capacidad de evaluar la participación, la democracia y la implementación de programas específicos, permitiéndote tomar decisiones informadas para mejorar el clima escolar y la convivencia.',
                    title2: 'Instrumento de Calidad Validado Metodológicamente',
                    description2: 'Diseñamos nuestras preguntas de diagnóstico basándonos en las mejores prácticas internacionales y en la colaboración con colegios asociados. Con más de 250 instituciones que han respondido exitosamente, garantizamos un instrumento validado metodológicamente. Al elegir Brave Up, confías en un proceso respaldado por la experiencia y el éxito de numerosas instituciones educativas.'
                }
            }
        },
        "braveup-resources": {
            home: {
                title: 'BRAVE UP! Recursos',
                subTitle: 'El poder de las actividades BRAVE UP!',
                subTitle1: '',
                description: 'Descubre la esencia de la educación efectiva con BRAVE UP! Nuestra plataforma ofrece recursos especializados, desde videos inspiradores hasta actividades diseñadas para conectar con la realidad de estudiantes, familias, y equipos educativos. Proporcionamos herramientas que transforman desafíos diarios en ambientes escolares sanos y positivos. ¡Únete a la revolución educativa hoy mismo!',
                noImage: 'Diagnostico de convivencia escolar'
            },
            description: {
                title: '¿Qué encontrarás en nuestros recursos?',
                description: '',
                items:{
                    title: 'Contenidos Impactantes',
                    description: 'Sumérgete en contenidos educativos que abordan de frente los problemas que los(as) estudiantes y la comunidad educativa enfrentan en su día a día. Desde temas relevantes hasta soluciones prácticas, nuestros recursos están diseñados para ser informativos y transformadores.',
                    title1: 'Videos Inspiradores',
                    description1: 'A través de vídeos cautivadores, llevamos a la comunidad educativa a un viaje de reflexión y descubrimiento. Nuestros materiales visuales no solo educan, sino que también inspiran y motivan, creando un impacto duradero.',
                    title2: 'Actividades Prácticas',
                    description2: 'Proporcionamos actividades diseñadas para fomentar la participación activa y el aprendizaje práctico. Desde dinámicas de grupo hasta ejercicios individuales, nuestros recursos ofrecen herramientas versátiles para cualquier entorno educativo.',
                }
            },
            reasons: {
                title: '¿Por qué elegir los Recursos BRAVE UP!?',
                subTitle: '',
                items: {
                    title: 'Enfoque en el bienestar socioemocional',
                    description: 'Nuestros recursos están cuidadosamente elaborados para conectar con las experiencias y desafíos reales de los(as) estudiantes, fomentando así un aprendizaje más significativo.',
                    title1: 'Integración con BRAVE UP!',
                    description1: 'Al formar parte de la plataforma BRAVE UP!, nuestros recursos se alinean perfectamente con nuestra misión de fortalecer la salud mental y emocional de los(as) estudiantes y la comunidad educativa, creando un ecosistema educativo completo.',
                    title2: 'Resultados demostrados',
                    description2: 'Con el respaldo de escuelas y educadores(as) que confían en BRAVE UP!, nuestros recursos han demostrado ser herramientas efectivas para mejorar la comprensión y abordar los desafíos cotidianos que afectan a los(as) estudiantes y la comunidad en torno al clima y convivencia escolar.'
                }
            }
        },
        // NEW PAGES
        newhome: {
            home:{
                title: 'BRAVE UP!',
                subTitle: '¡Potencia',
                subTitle1: 'tu gestión',
                subTitle2: 'en convivencia!',
                description: 'Estrategias basadas en evidencia para fomentar la sana Convivencia Escolar con BRAVE UP: datos, sociogramas, reportes en tiempo real y una metodología de trabajo anual',
                noImage: 'brave-up-convivencia-escolar.jpg'
            },
            testimonials: {
              title: 'Testimomios',
              first: {
                name: "Andrea Valenzuela",
                text: "Estamos muy agradecidos del MODO BRAVEUP!, que nos ha permitido ver situaciones que no podríamos haber visto sin esta función.",
                role: "Encargada de Convivencia Colegio SSCC Manquehue",
                location: "Chile"
            },
              second:   {
                name: "Patricio Rojas",
                text: "BRAVE UP! DATA nos facilita la gestión escolar al proporcionarnos herramientas para el diagnóstico integral y las recomendaciones claves para avanzar en la mejora continua de la convivencia escolar presencial y digital.",
                role: "Director Básica Colegio San Agustín de Atacama",
                location: "Chile"
            },
              third: {
                name: "Nelson Rojas",
                text: "Estos emprendedores se hacen cargo de problemas sociales que aquejan a nuestro país con propuestas tremendamente desafiantes e innovadoras.",
                role: "Caja Los Andes",
                location: "Chile"
              }

             },
            logos: {
                noFigure: 'rectangule',
                description: 'Nuestro compromiso con la excelencia crece con más de 50.000 usuarios',
                items: {}
            },
            features: {
                title: 'Conoce las funciones de la plataforma',
                items: {
                    'braveup-data': {
                        title: 'SOCIOGRAMAS',
                        description: 'Mejora la Convivencia Escolar con estrategias efectivas, datos, reportes en tiempo real y una metodología anual.',
                    },
                    'modo-braveup': {
                        title: 'MODO BRAVE UP!',
                        description: 'Un canal para mejorar la convivencia escolar, crucial para fortalecer la confianza en tu comunidad educativa.',
                    },
                    'braveup-acciona': {
                        title: 'ACCIONA',
                        description: 'Sistema que conecta redes y recursos educativos, fortaleciendo la convivencia escolar al unir expertos con la comunidad para recomendaciones efectivas.',
                    },
                    'braveup-resources' : {
                        title: 'RECURSOS',
                        description: 'BRAVE UP! promueve la educación con recursos que unen a la comunidad educativa, transformando desafíos en ambientes positivos.',
                    },
                    "braveup-dce": {
                        title: 'DIAGNÓSTICO CONVIVENCIA ESCOLAR',
                        description: 'Herramienta clave para mejorar la convivencia, obtener una radiografía de tus cursos y apoyar a cada estudiante.',
                    }
                }
            },
            data: {
                title: 'Estudiantes',
                value: '+ 40.000',
                description: 'Exploramos la esencia educativa a través de sociogramas, convirtiendo la información en soluciones que fomentan un entorno educativo saludable y motivador.',
                
                title1: 'Víctimas',
                value1: '8,7%',
                description1: 'El 8.7% de los(as) estudiantes se reconocen como víctimas, un dato revelador que impulsa nuestra misión de crear un entorno escolar seguro, inclusivo y libre de acosos.',
                
                title2: 'Países',
                value2: '+ 7',
                description2: 'Unimos corazones en más de siete países, mejorando la gestión y la convivencia escolar. Inspiramos y fomentamos la excelencia en cada comunidad educativa.',
                
                title3: 'Clases intervenidas',
                value3: '+ 1.000',
                description3: 'Enriquecemos la experiencia educativa en más de 1.000 aulas, mejorando gestión y convivencia escolar con inspiración y excelencia.',
            },
        },
        callToAction: {
          title: 'Prueba BRAVE UP! en un curso en tu colegio',
          action: '¡Quiero tomar acción!'
        }
    },
    contact:{
        title: '¡Da el paso para transformar la Convivencia Escolar!',
        supportTitle: 'Apoyados por las aceleradoras START UP CHILE, STARTED y eLab',
        infoAward: {
          text1: 'Seleccionados como una de las startups de EdTech más prometedoras en América del Norte en la lista anual The North America EdTech de HolonIQ.',
          text2: 'Seleccionados como "Elite 200". Semifinalista en la Competencia más Grande del Mundo para Startups de EdTech (GSV Cup Elite 200).',
          text3: 'Ganadores del fondo CORFO "Crea y Valida"; para la construcción e impacto de la plataforma BRAVE UP!.'
        },
        form:{
            buttonText: 'Obtén Demo',
            titleForm: 'Solicita tu demo Hoy',
            name: 'Nombre',
            email: 'Correo electrónico',
            country:'País', 
            position: '¿Cúal es tú rol?',
            school: 'Colegio',
            phone:'Número de teléfono',
            products:'¿Cúal es el producto de tú interés?',
            howKnows:'¿Cómo nos conociste?',
            sendButton: 'Enviar Mensaje',
            register:'Registrarse',
            successMessage:'Muchas gracias por solicitar tu demo, en breve alguien del equipo de BRAVE UP! te contactará',
            errorMessage: 'Ha ocurrido un error, porfavor recarga la pagina e intentalo de nuevo'
            },
        label: {
          name: 'Nombre',
          email: 'Email',
          position: 'Cargo',
          other: 'Otro',
          school: 'Colegio',
          phone: 'Teléfono',
          country: 'País',
          products: 'Funciones',
          options: 'Cuentanos',
          send: 'Enviar'
        },
        roles: {
          owner: 'Sostenedor',
          dean: 'Director',
          counselor: 'Encargado de Convicencia',
          teacher: 'Profesor',
          other: 'Otro'
        },
        functions: {
          sociogram: 'Sociogramas',
          modoBup: 'Modo BRAVE UP!',
          action: 'Acciona',
          resources: 'Recursos',
          survey: 'Diagnóstico Convivencia Escolar'
        },
        options: {
          social:'Redes sociales',
          engine: 'Motor de búsqueda (Google, Bing, Safari)',
          email: 'Correo electrónico',
          recommendation: 'Recomendación de un amigo',
          web: 'Sitio Web',
          event: 'Eventos o conferencias',
          advertising: 'Publicidad'
        }
    },
    footer: {
        brand: 'Todos los derechos reservados',
        instagram: 'https://www.instagram.com/braveup_esp',
    },
}