
import styles from './description.module.scss'
import halfCircle from '../../../assets/float-3.svg';
import { useTranslation } from 'react-i18next';

const DescriptionComponent = ({ name, images }) => {
	
    const [t] = useTranslation()
  return (
    <section id={name} className={styles.container}>
			<div className={styles.content_wrapper}>
				<h2>{t(`section.${name}.description.title`)}</h2>
				<div className={styles.infoSteps}>
					<div className={styles.step}>
						<img src={images["image-1"]} className={styles.stepImage} />
						<div className={styles.text_wrapper_right}>
							<h3>
								{t(`section.${name}.description.items.title`)}
							</h3>
							<p className={styles.stepDescription}>
								{t(`section.${name}.description.items.description`)}
							</p>
						</div >
					</div>
					<div className={styles.step}>
						<div className={styles.text_wrapper_left}>
							<h3>
								{t(`section.${name}.description.items.title1`)}
							</h3>
							<p className={styles.stepDescription}>
								{t(`section.${name}.description.items.description1`)}
							</p>
						</div>
						<img src={ images["image-2"]} className={styles.stepImage} />
					</div>
					<div className={styles.step}>
						<img src={ images["image-3"]} className={styles.stepImage} />
						<div className={styles.text_wrapper_right}>
							<h3>
								{t(`section.${name}.description.items.title2`)}
							</h3>
							<p className={styles.stepDescription}>
								{t(`section.${name}.description.items.description2`)}
							</p>
						</div>
					</div>
				</div>
				<img alt='half circle' src={halfCircle} className={styles.halfCircle} />
			</div>
		</section>
  )
}

export default DescriptionComponent